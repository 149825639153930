import React, {useState, useCallback} from 'react';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"

// Styles
import styles from './index.module.scss';

// Assets

export const Insurance = ({openChatbot})=>{
  const [open, setOpen] = useState(true);

  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.tile}>
            <div className={styles.content}>
              <div className={styles.profile}>
                <div className={styles.profileText}>
                  <div className={styles.title}>Get Affordable Insurance, By Nomads, For Nomads!</div>
                </div>
              </div>

              <div onClick={(e)=>{  }} className={styles.button}>
                Get Health Insurance <Emoji name="hospital" className={styles.emoji} width={18} />
              </div>


              <div onClick={(e)=>{
                openChatbot(true)
               }} className={styles.chatbotButton}>
                Get Travel Insurance <Emoji name="airplane" className={styles.emoji} width={18} />
              </div>
            </div>
       </div>
    </EmojiProvider>
   
  )


}

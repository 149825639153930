
import React,{ useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { ClientMenu } from '../../components/ClientMenu';
import { Header } from '../../components/Header';
import { Insurance } from '../../components/Insurance';
import { DocumentsOverview } from '../../components/DocumentsOverview';
import { PopupWindow } from '../../components/PopupWindow';
import styles from './styles.module.scss'
import { MonthlyOverview } from '../../components/MonthlyOverview';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ChatBot } from '../../components/ChatBot'
import { Intro } from '../../components/Intro'
import { ClipLoader } from "react-spinners";
import principalSlice from '../../slices/principalSlice'
import authSlice from '../../slices/authSlice'
import store from '../../store'

export const Client = () => {
  const navigate = useNavigate();
  const user = useSelector(state => state.authSlice?.user)
  const [content, setContent] = useState(null)
  const [documentId, setDocumentId] = useState(null)
  const [loadingFile, setLoadingFile] = useState(null)
  const [newDocumentName, setNewDocumentName] = useState(null)
  const [newDocument, setNewDocument] = useState(null)
  const [chatbotOpen, setChatbotOpen] = useState(false)
  
  useEffect(()=>{
    console.log(user)
  },[])

  const uploadDocument = async()=>{
    await setLoadingFile(true)
    console.log(documentId)
    const res = await store.dispatch(principalSlice.async$.createDocument({file:newDocument,notes:'',documentId}))
    await store.dispatch(authSlice.async$.loadProfile())

    setLoadingFile(false)
    setNewDocument(null)
    setNewDocumentName('')
    setDocumentId(null)
  }

  const onDocumentSelect = (e)=>{
    setNewDocumentName(e.target.files[0].name)
    setNewDocument(e.target.files[0])
  }

  return (
    <div className={styles.screen}>
        <ClientMenu activeScreen={'Dashboard'} />
        <div className={styles.content}>
          <Header name={user?.name} />
          <div className={styles.tiles}>
            <div className={styles.column}>
              <DocumentsOverview setInfoWindowContent={(content)=>{ setContent(content) }} setDocumentId={(id)=>{ setDocumentId(id) }} />
            </div>
            <div className={styles.column}>
            <Insurance />
            <MonthlyOverview />
            </div>
          </div>
        </div>
        <PopupWindow windowStyles={{width:'60%'}} title={<EmojiProvider data={emojiData}> Document Information<Emoji name="globe-showing-europe-africa" className={styles.emoji} width={22} /></EmojiProvider>} onClose={()=>{ setContent(null) }} open={content}>{content}</PopupWindow>
        <PopupWindow windowStyles={{width:'60%'}} title={<EmojiProvider data={emojiData}> Upload A New Document<Emoji name="globe-showing-europe-africa" className={styles.emoji} width={22} /></EmojiProvider>} onClose={()=>{ setDocumentId(null) }} open={documentId}>
        <div className={styles.center}>
          <label for="logoInputDash">
                  <div className={styles.fileButton} onClick={(e)=>{  }}>
                      Select File
                  </div>
                </label>
                <br/>
                <input disabled={loadingFile} accept=".png,.jpeg,.jpg,.pdf,.docx,.doc" onChange={(e)=>{onDocumentSelect(e)}} style={{visibility:"hidden",width:"0px"}} type="file" id="logoInputDash" name="logoInputDash" />
                {newDocumentName?
                  <div className={styles.fileLabel}>
                      {newDocumentName}
                  </div>
                  :
                  <></>
                }
                <br/>
                {
                  (loadingFile)?
                  <ClipLoader size={50} color={'#5603fc'} />
                  :
                  <div className={styles.documentsButton} onClick={(e)=>{ uploadDocument() }}>
                    Upload
                  </div>
                }
        </div>
        </PopupWindow>

        <ChatBot open={chatbotOpen} setOpen={(v)=>{setChatbotOpen(v)}} />
        <Intro />
    </div>
  )
}
import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { EmojiProvider } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ClipLoader } from "react-spinners"
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import check from '../../images/check.png'

import SplitForm from '../../components/CardForm';

// Styles
import styles from './index.module.scss';

// Assets

let lastClick = 0

export const Stripe = ({setInfoWindowContent, setDocumentId})=>{

  const user = useSelector(state => state.authSlice?.user)
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [nieOpen, setNieOpen] = useState(false)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState('');
  const [clientSecret, setClientSecret] = useState(null)
  const [emailSetup, setEmailSetup] = useState(false);
  const [stripeProm, setStripeProm] = useState(null)
  const [payload, setPayload] = useState(null)
  const cardRef = useRef();
  
  useEffect(() => {

  }, [])

  useEffect(async() => {
    const stripePromise = await loadStripe('pk_test_51Q14Ci07xSH0IW8SReuqi80QWpVAdbeoxOmEaMXzgbWPAq0oW1FzbpAOBPNOLQqzCrINxW6y2qQhBlkkrcn6LeJ300S21AwnzI');
    setStripeProm({...stripePromise})
  }, [])

  const encodeQueryData = (data)=>{
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
  }

  const setupEmail = async()=>{
    setLoading(true)
    lastClick = new Date().getTime();

    fetch("http://localhost:3000/retailer/createSetupIntent",{
      method: "POST",
      body:encodeQueryData({
        email
      }),
      headers: {
        'Accept':       'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      })
      .then((res) => {
        if(res.status == 200){
          res.json().then(async(data)=>{
            setClientSecret(data.clientSecret)
            setEmailSetup(true)
          })
        }
      })


    setLoading(false)
  }

  const submitPayment = async()=>{
    if(lastClick && ((new Date().getTime() - lastClick) < 2000)) return

    setLoading(true)
    lastClick = new Date().getTime();

      const payload = await cardRef.current.handleSubmit()

      stripeProm.confirmCardSetup(
        clientSecret, 
        {
          payment_method: payload.paymentMethod.id
        }
      ).then(function(result) {
        console.log(result)
        if(result?.setupIntent?.status == 'succeeded'){
          setSubmitted(true)
          fetch("http://localhost:3000/retailer/setDefault",{
            method: "POST",
            body:encodeQueryData({
              paymentMethodId: payload.paymentMethod.id,
              email
            }),
            headers: {
              'Accept':       'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          })
          setLoading(false)
        }else{
          alert('Error. Please try again')
          setLoading(false)
        }

      }).catch((error)=>{
      })


  }


  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.tile}>
          <br/>
            <div className={styles.content}>
              <div className={styles.tileSubtitle}>Complete Your LoyalTilly Subscription Below.</div>
                {
                (submitted)?
                            <div className={styles.submissionText}>
                              <br/><br/><br/><br/>
                              <img src={check} className={styles.checkIcon} /><br/><br/>
                              Congratulations, you're subscription was updated!<br/><br/>
                            </div>
                :
                ((!emailSetup)?
                  <>
                      <input type="email" className={styles.textInput} value={email} onChange={(event)=>{setEmail(event.target.value)}} placeholder={'LoyalTilly Retailer Email'} />
                      <br/><br/>
                      <div className={styles.saveButton} onClick={(e)=>{ setupEmail() }}>
                        Next Step
                      </div>
                  </>
                  :
                  <>
                  <br/><br/><br/><br/>
                  <Elements stripe={stripeProm}>
                    <ElementsConsumer>
                      {({elements, stripe}) => (
                        <SplitForm context={this} setPayload={async(v)=>{
                          await setPayload({...v})
                        }} ref={cardRef} elements={elements} stripe={stripe} />
                      )}
                    </ElementsConsumer>
                  </Elements>
                  {error?<div className={styles.error}>{error}</div>:<></>}
                    
                  <div className={styles.submit}>
                    {(loading)?
                    <ClipLoader size={50} color={'#5603fc'} />
                    :
                    <div className={styles.saveButton} onClick={(e)=>{ submitPayment() }}>
                    Next Step
                    </div>
                    }
                  </div>
                  </>)
                }
              </div>
            </div>
          
    </EmojiProvider>
   
  )


}

import React,{ useCallback, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { PopupWindow } from '../../components/PopupWindow';
import {TextInput} from '../../components/TextInput/index.js';
import {SubmitButton} from '../../components/SubmitButton/index.js';
import store from '../../store'
import authSlice from '../../slices/authSlice'
import Select from "react-select"
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import { ClipLoader } from "react-spinners"

const REQUIREMENTS = ['I Need my NIE / TIE','I Need To Register For Tax']
const Range = ({ref, key, question, onChange, value, placeholder, currency})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          <div className="rangeCurrency">{currency}</div>
          <input key={key} ref={ref} id={key} type="number" className="rangeInput" placeholder={placeholder} value={value} onChange={(event)=>{ onChange(event.target.value) }} />
        </div>
    </div>
  )
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}


const Radio = ({question, options, onChange, selected})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <div className="radioOptions">
          {
            options.map((option)=>{
              return(
                (option === selected)?
                <div className="radioOptionSelected">{option}</div>
                :
                <div className="radioOption" onClick={(e)=>{ onChange(option) }}>{option}</div>
              )
            })
          }
        </div>
    </div>
  )
}


export const Signup = () => {
  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState(null)
  const error = useSelector(state => state.authSlice?.error)
  const [loading,setLoading] = useState(true)
  const [signedUp,setSignedUp] = useState(false)
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const workType = useSelector(state => state.authSlice?.workType)
  const country = useSelector(state => state.authSlice?.country)
  const employerCountry = useSelector(state => state.authSlice?.employerCountry)
  const [signupCode, setSignupCode] = useState('')
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(()=>{
    setLoading(true)
    store.dispatch(authSlice.actions.setWorkType(searchParams.get('workType') || workType))
    store.dispatch(authSlice.actions.setCountry(searchParams.get('country')?.split(',') || country))
    store.dispatch(authSlice.actions.setEmployerCountry(searchParams.get('employerCountry') || employerCountry))
    store.dispatch(authSlice.actions.setEstimatedIncome(searchParams.get('estimatedIncome') || estimatedIncome))
    store.dispatch(authSlice.actions.setInSpain((searchParams.get('inSpain') == 1) || false))
    setSignupCode(searchParams.get('c') || '')
    
    setLoading(false)
  },[]);

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();
      authSlice.actions.setError('')
      const res = await store.dispatch(authSlice.async$.attemptSignup({email, password, name, signupCode}))
      if(res.payload?.success){
        setSignedUp(true)
        console.log(res)
      }else{
        console.log(res)
        store.dispatch(authSlice.actions.setError('Something went wrong. Please try again.'))
      }
    },
    [email, password, name]
  )


  if(signedUp){
    return (
      <EmojiProvider data={emojiData}>
      <div className="global-container">
          <div className={styles.loginLogoContainer}>
            <img src={require('../../images/logo.png')} className={styles.loginLogo} />
            <div className={styles.loginLogoText}>SimplyNomad</div>
          </div>
          <div id={styles.loginPanel}>
            <div id={styles.loginHeaderMain}>You're Ready To Go!</div>
            <br/>
            <div className={styles.affiliateText}>Congratulations, you have created your account! Download our app here: </div>
            <br/><br/>
            <a href={'https://apps.apple.com/us/app/simply-nomad/id6740445421'}><img className={styles.appstore} src={require('../../images/appstore.png')}></img></a><br/><br/>
            <a href={'https://play.google.com/store/apps/details?id=com.simplynomad.app'}><img className={styles.appstore} src={require('../../images/googleplay.png')}></img></a><br/><br/>
          </div>
  
          <div className={styles.loginCopyright}>
          </div>
      </div>
      </EmojiProvider>
    )
  }


  if(loading) return (<div className={styles.center}><ClipLoader size={50} color={'#5603fc'} /></div>)


  return (
    <EmojiProvider data={emojiData}>
    <div className="global-container">
        <div className={styles.loginLogoContainer}>
          <img src={require('../../images/logo.png')} className={styles.loginLogo} />
          <div className={styles.loginLogoText}>SimplyNomad</div>
        </div>
        <div id={styles.loginPanel}>
          <div id={styles.loginHeaderMain}>Sign Up Now!</div>
          <br/>
          <TextInput placeholder={'Full Name'} type={'onboarding-input'} onChange={(e)=>{setName(e.target.value)}} />
          <TextInput placeholder={'Email'} type={'email'} onChange={(value)=>{setEmail(value)}} />
          <TextInput placeholder={'New Password'} type={'password'} onChange={(value)=>{setPassword(value)}} /><br/><br/><br/>
          <div className={styles.affiliateText}>Affiliate Code (Optional). Enter the code given to you by a friend for special rewards:</div>
          <TextInput placeholder={'Affiliate Code'} type={'onboarding-input'} value={signupCode} initialValue={signupCode} onChange={(value)=>{setSignupCode(value)}} />
          <SubmitButton loading={loading} title={'Sign Up'} emoji={<Emoji name="rocket" className="emoji" width={22} />} onClick={onSubmit} />
          <br/>
          <div className={styles.loginError}>{error}</div>
          <br/>
          <div id={styles.loginLinks}>
            <a className={styles.loginLink} href="./login">Login</a>
          </div>
        </div>

        <div className={styles.loginCopyright}>
        </div>
    </div>
    </EmojiProvider>
  )
}
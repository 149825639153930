import React, { useState } from 'react';
import styles from './styles.module.scss';

export const Checkbox = ({onClick, label, checked}) => {


  return (
    <div className={styles.checkbox}>
      <input type="checkbox" id="checkbox1" name="checkbox1" checked={checked} onChange={onClick}></input>
      <span style={{paddingRight:"0px"}}> {label}</span> 
    </div>
  )

}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { get,post, put } from '../utils/request';
import Cookies from "universal-cookie";

const cookies = new Cookies();
const token = cookies.get("token");

export const loadProfile = createAsyncThunk('auth/loadProfile', async () => {

  if(!token){
    return {loggedIn:false}
  }

  const response = await get('/auth/profile',{})

  if(response.data?.success){
    
  }
  return {...response.data, loggedIn:true}
})

export const getClientDocuments = createAsyncThunk('auth/getClientDocuments', async (id) => {
  const response = await get('/accountants/client/'+id+'/documents',{})

  if(response.data?.success){
    
  }
  return {...response.data}
})

export const submitForms = createAsyncThunk('auth/submitForms', async ({id,name,}) => {
  const response = await post('/clients/submitForms',{id,name,})

  if(response.data?.success){
    
  }
  return {...response.data}
})

export const attemptLogin = createAsyncThunk('auth/attemptLogin', async ({email, password}) => {
  const response = await post('/auth/login',{email,password})

  if(response.data?.success){
    
  }

  return response.data
})

export const attemptAccountantLogin = createAsyncThunk('auth/attemptAccountantLogin', async ({email, password}) => {
  const response = await post('/auth/accountantLogin',{email,password})

  if(response.data?.success){
    
  }

  return response.data
})

export const requestNIE = createAsyncThunk('auth/requestNIE', async ({city,
  situation,
  country,
  message,
  requirement,
  paymentMethod
}) => {
  const response = await post('/clients/nie',{city,
    situation,
    country,
    message,
    requirement,
    paymentMethod
  })

  if(response.data?.success){
    
  }

  return response.data
})

export const attemptSignup = createAsyncThunk('auth/attemptSignup', async ({name, email, password, signupCode}) => {
  const response = await post('/auth/signup',{name, email, password, signupCode})
  console.log(response)
  if(response.data?.success){
    
  }
  return response.data
})

export const updateProfile = createAsyncThunk('auth/updateProfile', async ({userName, workType,country, employerCountry,estimatedIncome, requirement, inSpain}) => {
  const response = await put('/clients/profile',{name:userName, inSpain, requirement, businessType:workType, passports:JSON.stringify(country),employerCountry,annualIncomeEstimate:estimatedIncome})
  
  if(response.data?.success){
    
  }
  console.log(response.data)
  return response.data
})

export const updateAccountantProfile = createAsyncThunk('auth/updateAccountantProfile', async ({userName, userDescription}) => {
  const response = await put('/accountants/profile',{name:userName, description: userDescription})
  if(response.data?.success){
    
  }
  return response.data
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    status:'initial',
    clientDocuments:[],
    clientInvoices: [],
    error:'',
    requirement: 'I Need my NIE / TIE',
    workType: 'I Am A Freelancer',
    country: ['ES'],
    employerCountry: 'ES',
    inSpain: true,
    estimatedIncome: 24000,
    submissions:[]
  },
  reducers: {
    setOnboarded: (state, value) => {
      state.user = {...state.user,onboarded:1,requirement:state.requirement}
    },
    setWorkType: (state, value) => {
      state.workType = value.payload
    },
    setInSpain: (state, value) => {
      state.inSpain = value.payload
    },
    changeInSpain: (state, value) => {
      state.inSpain = !state.inSpain
    },
    setCountry: (state, value) => {
      console.log(value)
      state.country = value.payload
    },
    setEmployerCountry: (state, value) => {
      state.employerCountry = value.payload
    },
    setEstimatedIncome: (state, value) => {
      state.estimatedIncome = value.payload
    },
    setUserName: (state, value) => {
      state.userName = value.payload
    },
    setUserDescription: (state, value) => {
      state.userDescription = value.payload
    },
    setInvoiceFrom: (state, value) => {
      state.invoiceFrom = value.payload
    },
    setRequirement: (state, value) => {
      state.requirement = value.payload
    },
    logout: (state, value) => {
      state.status = 'public'
      state.user = null 
      state.type = null
      state.token = null
    },
    setClientDocuments: (state, value) => {
      state.clientDocuments = value.payload
      state.clientDocumentsOpen = true
    },
    closeDocumentsWindow: (state, value) => {
      state.clientDocumentsOpen = false
    },
    setClientInvoices: (state, value) => {
      state.clientInvoices = value.payload
      state.clientInvoicesOpen = true
    },
    closeInvoicesWindow: (state, value) => {
      state.clientInvoicesOpen = false
    },
    setError: (state, value) => {
      console.log(value.payload)
      console.log('GFFG')
      console.log(value)
      state.error = value.payload
    }
  },
  extraReducers: (builder)=>{
    builder
      .addCase(attemptLogin.fulfilled, (state, action) => {

        if(action.payload?.success){

          cookies.set("token", action.payload.token);
          state.user = action.payload.client
          state.workType = action.payload.client.businessType 
          state.userName = action.payload.client.name
          state.invoiceFrom = action.payload.client.invoiceFrom || ''
          state.country = JSON.parse(action.payload.client.passports || '[]')
          state.employerCountry = action.payload.client.employerCountry
          state.estimatedIncome = action.payload.client.annualIncomeEstimate
          state.uploadedDocuments = JSON.parse(action.payload.client.uploadedDocuments || '[]')
          state.submissions = JSON.parse(action.payload.client.submissions || '[]')
          state.accountant = action.payload.accountant
          state.lawyer = action.payload.lawyer
          state.type = 'client'
          state.status = 'authenticated'
          state.token = action.payload.token

        }else if(!action.payload?.loggedIn){
          state.error = 'Error logging you in. Please try again.'
          console.log(action.payload)
        }
      })
      .addCase(requestNIE.fulfilled, (state, action) => {

        if(action.payload?.success){

        }else if(!action.payload?.loggedIn){
        }
      })
      .addCase(attemptAccountantLogin.fulfilled, (state, action) => {

        if(action.payload?.success){

          console.log('all fine')

          cookies.set("token", action.payload.token);
          state.user = action.payload.accountant
          state.clients = action.payload.clients
          state.userDescription = action.payload.description
          state.type = 'accountant'
          state.status = 'authenticated'
          state.token = action.payload.token

        }else if(!action.payload?.loggedIn){
          state.error = 'Error logging you in. Please try again.'
          console.log(action.payload)
        }
      })
      .addCase(attemptSignup.fulfilled, (state, action) => {
        if(action.payload?.success){
          cookies.set("token", action.payload.token)
          state.user = action.payload.user
          state.type = 'client'
          state.token = action.payload.token
          console.log(action.payload.token)
        }
      })
      .addCase(getClientDocuments.fulfilled, (state, action) => {
        console.log(action)
        if(action.payload?.success){
          state.clientDocuments = action.payload.documents
          state.clientDocumentsOpen = true
        }
      })
      .addCase(submitForms.fulfilled, (state, action) => {
     
        if(action.payload?.success){
          state.error = ''
          state.submissions = JSON.parse(action.payload.client?.submissions || '[]')
        }else{
          state.error = action.payload?.error
        }

      })
      .addCase(loadProfile.fulfilled, (state, action) => {

        if(action.payload.success){
          state.user = action.payload.profile 
          state.workType = action.payload.profile.businessType 
          state.userName = action.payload.profile.name
          state.userDescription = action.payload.profile.description
          state.invoiceFrom = action.payload.profile.invoiceFrom || ''
          state.country = JSON.parse(action.payload.profile.passports || '[]')
          state.employerCountry = action.payload.profile.employerCountry
          state.estimatedIncome = action.payload.profile.annualIncomeEstimate
          state.accountant = action.payload.accountant
          state.lawyer = action.payload.lawyer
          state.documents = action.payload.documents
          state.invoices = action.payload.invoices
          state.submissions = JSON.parse(action.payload.profile.submissions || '[]')
          state.clients = action.payload.clients
          state.type = action.payload.type
          state.uploadedDocuments = JSON.parse(action.payload.profile.uploadedDocuments || '[]')
          state.status = 'authenticated'

        }else if(!action.payload.loggedIn){
          state.status = 'public'
        }

        state.loaded = true

      })
      .addCase(updateProfile.fulfilled, (state, action) => {

        if(action.payload.success){
          state.user = action.payload.profile
          state.status = 'authenticated'
        }

        state.loaded = true

      })
  }
})

authSlice.async$ = {
  attemptLogin,
  attemptAccountantLogin,
  attemptSignup,
  loadProfile,
  updateProfile,
  updateAccountantProfile,
  getClientDocuments,
  submitForms,
  requestNIE
}

export default authSlice
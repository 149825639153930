import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { EmojiProvider, Emoji } from "react-apple-emojis"
import emojiData from "react-apple-emojis/src/data.json"
import {nomadVisaAvailable, nomadVisaContent, nomadVisaDocuments } from '../../utils/nomadVisaAvailable';
import store from '../../store'
import { ClipLoader } from "react-spinners"
import principalSlice from '../../slices/principalSlice'
import authSlice from '../../slices/authSlice'
import { PopupWindow } from '../PopupWindow'
import { Checkbox } from '../Checkbox'
import Select from "react-select"
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";

import SplitForm from '../../components/CardForm';

// Styles
import styles from './index.module.scss';

// Assets
import documents from '../../images/flow-icon-4.png';
import check from '../../images/check.png'
import info from '../../images/information-white.svg'
import { TextInput } from '../TextInput';

let lastClick = 0

const SERVICE_OPTION_IDS = [
  'NIE/ TIE',
  'NIE/ TIE Renewal',
  'Driving licence change',
  'Social Security Number',
  'Empadronamiento',
  'Bank Account',
  'Other'
]

const SITUATION_IDS = [
  'Work',
  'Study',
  'Start a Business',
  'Join a family member',
  'Buy a property',
  'Other'
]

const CITIES = [
  'Barcelona',
  'Madrid',
  'Valencia'
]

const SERVICE_OPTION_PRICES = {
  'NIE/ TIE':119,
  'NIE/ TIE Renewal':75,
  'Driving licence change':119,
  'Social Security Number':60,
  'Empadronamiento':75,
  'Bank Account':50
}

const CountrySelect = ({question, value, onChange, countries, isMulti})=>{
  return(
    <div className="radio">
        <div className="radioQuestion">{question}</div>
        <Select
          isMulti={isMulti}
          name="colors"
          options={countries}
          className="countryInput"
          value={value}
          isSearchable={true}
          onChange={(val) => onChange(val)}
          menuPortalTarget={document.querySelector('body')}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
    </div>
  )
}

export const DocumentsOverview = ({setInfoWindowContent, setDocumentId})=>{

  const user = useSelector(state => state.authSlice?.user)
  const estimatedIncome = useSelector(state => state.authSlice?.estimatedIncome)
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [nieOpen, setNieOpen] = useState(false)
  const [error, setError] = useState(null)
  const [countries, setCountries] = useState([]);
  const country = useSelector(state => state.authSlice?.country)
  const [serviceOptions, setServiceOptions] = useState(SERVICE_OPTION_IDS.map((s)=>{ return {label:s, checked:0} }))
  const [situation, setSituation] = useState(SITUATION_IDS[0])
  const [message, setMessage] = useState('')
  const [city, setCity] = useState(CITIES[0])
  const [NIEError, setNIEError] = useState(null)
  const [stripeProm, setStripeProm] = useState(null)
  const [payload, setPayload] = useState(null)
  const cardRef = useRef();
  
  useEffect(() => {
    store.dispatch(principalSlice.async$.getMessages())
  }, [])

  useEffect(async() => {
    const stripePromise = await loadStripe('pk_test_51NwofrJeFMcJLFJXaaTYs9rAgR2DTClk2lcMlK8ThHwIs0326WtkQIyFCVx8LZrdOhv1enRndhprAUDCHR5QbKou00rKVlKuEA');
    setStripeProm({...stripePromise})

    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries)
        console.log(data.countries)
      })
  }, [])

  const openNie = ()=>{
    if(!serviceOptions.filter((s)=>{ return s.checked }).length) return setError('Please choose a service you require.')
    if(!country.length) return setError('Please choose a country you have a passport from.')
    setError(null)
    setNieOpen(true)
  }

  const submitPayment = async()=>{
    if(lastClick && ((new Date().getTime() - lastClick) < 2000)) return

    setLoading(true)
    lastClick = new Date().getTime();

      const payload = await cardRef.current.handleSubmit()

      if(payload.error){
        setLoading(false)
        setError(payload.error.message)
        return;
      }

    let res = await store.dispatch(authSlice.async$.requestNIE({
      city,
      situation,
      country:country?.map((c)=>{
        return countries.filter((option)=>{ return option.value === c })[0]?.label
      }).join(', '),
      message,
      requirement:serviceOptions.filter((s)=>{ return s.checked }).map((s)=>{ return s.label }),
      paymentMethod:payload.paymentMethod.id
    }))
    if(res.payload?.success){
      setSubmitted(true)
    }else if(res.payload?.actionRequired){
      setLoading(false)
      const { paymentIntent, error } = await stripeProm.confirmCardPayment(
          res.payload.clientSecret
        )

        if (error) return alert("Error with payment, please try again.");
        if (paymentIntent.status === "succeeded"){
          setLoading(true)
          const confirmPaymentData = await store.dispatch(authSlice.async$.requestNIE({
            check:true,
            paymentIntentId:res.payload?.paymentIntentId
          }))
          if(confirmPaymentData.payload.success){
            setSubmitted(true)
          }else{
            alert("Error with payment, please try again.")
          }
        }


    }else{
      console.log(res.payload)
      setNIEError(res.payload?.error || 'An unknown error occurred. Please try again.')
    }
    setLoading(false)
  }


  return(
    <EmojiProvider data={emojiData}>
       <div className={styles.tile}>
          <div className={styles.title}><img src={documents} className={styles.titleIcon} />NIE / TIE Application</div>
          <br/>
            <div className={styles.content}>
              <div className={styles.tileSubtitle}>Complete our form below to get started. A SimplyNomad representative will be in touch quickly to help ensure you get an appointment and your required documents quickly.</div>
              <div className={styles.documents}>
                {
                  (nomadVisaAvailable({passportCountries:JSON.parse(user.passports || '[]'),employerCountry:user.employerCountry,estimatedIncome}))?
                  <div className={styles.nomadDocument}>
                    <div className={styles.nomadDocumentsName}>You May Be Applicable For The Spanish Digital Nomad Visa!</div>
                    
                    <img className={styles.info} src={info} onClick={(e)=>{ setInfoWindowContent(nomadVisaContent()) }}></img>

                  </div>
                  :
                  <></>
                }
              </div>
            </div>
            <CountrySelect isMulti={true} question={'Which Countries Do You Hold A Passport From?'} countries={countries} value={country.map((c)=>{
              return countries.filter((option)=>{ return option.value === c })[0]
            })} onChange={(option)=>{ store.dispatch(authSlice.actions.setCountry(option.map((o)=>{ return o.value }))) }} />
            <br/><br/>
            <div className="radioQuestion">Please Select What You Need Below.</div>
            <br/>
            <div className={styles.options}>
              {
                serviceOptions.map((s,si)=>{
                  return <Checkbox label={s.label} checked={s.checked} onClick={(e)=>{ 
                    let tmp = [...serviceOptions]
                    tmp[si].checked = !tmp[si].checked
                    setServiceOptions(tmp)
                  }} />
                })
              }
            </div>
            <br/><br/>
            <div className="radioQuestion">What Do You Need This For?</div>
            <div className={styles.center}>
              <TextInput type={'dropdown-mandatory'} options={SITUATION_IDS} value={situation} onChange={(value)=>{ setSituation(value) }} customStyle={{marginRight:'0px'}}/>
            </div>
            <br/><br/>
            <div className="radioQuestion">What City Do You Want Your Appointment In?</div>
            <div className={styles.center}>
              <TextInput type={'dropdown-mandatory'} options={CITIES} value={city} onChange={(value)=>{ setCity(value) }} customStyle={{marginRight:'0px'}}/>
            </div>
            <br/><br/>
            <div className="radioQuestion">Additional Comments & Queries</div>
            <div className={styles.center}>
              <textarea onChange={(e)=>{setMessage(e.target.value)}} value={message} placeholder={'Comments...'} className={styles.query} />
            </div>
            <br/><br/>
            <table className={styles.benefitsList} style={{paddingLeft:'20px',paddingRight:'20px'}}>
              <tr className={styles.tr}>
                <td className={styles.td}>
                  <b>Total Admin Fee (Due Now):</b>
                </td>
                <td className={styles.td}><b>
                  €
                {
                  serviceOptions.filter((s)=>{ return s.checked }).reduce((total, r)=>{
                    return (total + SERVICE_OPTION_PRICES[r])
                  },0) * 0.25
                }
                </b></td>
                </tr>
              
              <tr className={styles.tr}>
                <td className={styles.td}>
                  Partner Fee (Not Due Until Later):
                </td> 
                <td className={styles.td}>€{
                  serviceOptions.filter((s)=>{ return s.checked }).reduce((total, r)=>{
                    return (total + SERVICE_OPTION_PRICES[r])
                  },0)
                }</td>
                </tr>
            </table>

            {error?<div className={styles.error}>{error}</div>:<></>}
                    
            <div className={styles.submit}>
              {(loading)?
              <ClipLoader size={50} color={'#5603fc'} />
              :
              <div className={styles.saveButton} onClick={(e)=>{openNie() }}>
              Next Step <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
              </div>
              }
            </div>
        </div>

      <PopupWindow windowStyles={{width:'80%'}} title={'NIE/ TIE Appointment Application'} onClose={()=>{ setNieOpen(null) }} open={nieOpen}>
          {
            (submitted)?
            <div className={styles.submissionText}>
              <img src={check} className={styles.checkIcon} /><br/><br/>
              Congratulations, you're application was submitted!<br/><br/>
              Our partners in {city} will be in touch soon. <br/><br/>
            </div>
            :
            <div className={styles.submissionText}>
              <div className={styles.nieColumns}>
                <div className={styles.nieColumn}>

                  <table className={styles.benefitsList}>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td>
                      <td className={styles.td}>A Quick And Cost-Effective Way To Get Your NIE / TIE</td>
                      </tr>
                    
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Our Partners Assist With Your Documentation</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Our Partners Book Your NIE Appointment For You</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td>
                      <td className={styles.td}>A Partner Will Accompany You To Your NIE Appointment On The Day</td>
                      </tr>
                    <tr className={styles.tr}>
                      <td className={styles.td}>
                        <img src={check} className={styles.checkIconSmall} /> 
                      </td> 
                      <td className={styles.td}>Get Support On Any Legal Queries</td>
                      </tr>
                  </table>

                </div>
                <div className={styles.nieColumn}>

                    <Elements stripe={stripeProm}>
                        <ElementsConsumer>
                          {({elements, stripe}) => (
                            <SplitForm context={this} setPayload={async(v)=>{
                              await setPayload({...v});
                            
                            }} ref={cardRef} elements={elements} stripe={stripe} />
                          )}
                        </ElementsConsumer>
                      </Elements>

                      <table className={styles.benefitsList}>
                        <tr className={styles.tr}>
                          <td className={styles.td}>
                            <b>Admin Fee (Due Now):</b>
                          </td>
                          <td className={styles.td}><b>€35.00</b></td>
                          </tr>
                        
                        <tr className={styles.tr}>
                          <td className={styles.td}>
                            Partner Fee (Not Due Until Later):
                          </td> 
                          <td className={styles.td}>€110.00</td>
                          </tr>
                      </table>

                      {NIEError?<div className={styles.error}>{NIEError}</div>:<></>}

                      {(loading)?
                      <ClipLoader size={50} color={'#5603fc'} />
                      :
                      <div className={styles.saveButton} onClick={(e)=>{ submitPayment() }}>
                      Pay & Send Application <Emoji name="bookmark-tabs" className={styles.emoji} width={18} />
                      </div>
                      }
                </div>
              </div>
              
            </div>
          }
      </PopupWindow>

    </EmojiProvider>
   
  )


}
